import {
  Box,
  Card,
  Flex,
  Radio,
  RadioGroup,
  Text,
  VStack,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import {
  BarChart,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  Bar,
  ResponsiveContainer,
} from "recharts";

const colors = [
  "#E0E0E0", // 最低金額
  "#90CAF9", // 淺藍
  "#81C784", // 淺綠
  "#4FC3F7", // 天藍
  "#4DB6AC", // 青綠
  "#7986CB", // 靛藍
  "#9575CD", // 紫色
  "#F06292", // 粉紅
  "#FF8A65", // 珊瑚
  "#FFB74D", // 橙色
  "#FFF176", // 黃色
  "#E57373", // 淺紅
  "#BA68C8", // 紫羅蘭
  "#4DD0E1", // 青藍
  "#FF5252", // 亮紅
  "#FF1744", // 最高金額 - 鮮紅
];

const calculateTotalAndUnit = (payload) => {
  const total = payload.reduce((acc, cur) => acc + cur.payload[cur.dataKey], 0);
  const unit = payload[0]?.unit;
  return { total, unit };
};

const CustomTooltip = ({ payload, active }) => {
  if (!active || !payload || payload.length === 0) return null;
  const { total, unit } = calculateTotalAndUnit(payload);

  return (
    <Card p={2} bg="gray.500" color="black" rounded="md" textAlign="left">
      <Text mb={2} fontWeight="bold" color="#212121" align="center">
        {unit === "筆"
          ? `總筆數: ${total} 筆`
          : `總金額: ${total.toLocaleString("en-US", {
              style: "currency",
              maximumFractionDigits: 0,
              currency: "USD",
            })}`}
      </Text>
      {payload.map(({ color, dataKey, payload }) => {
        const name = payload.name || dataKey;
        return (
          <Box key={dataKey} color={color} fontWeight="semibold" fontSize="sm">
            {name}: {payload[dataKey]} {unit} (
            {((payload[dataKey] / total) * 100).toFixed(1)}%)
          </Box>
        );
      })}
    </Card>
  );
};

const RevenueChart = ({ source }) => {
  const [type, setType] = useState("amount");
  // 動態生成 names
  const names = useMemo(() => {
    const nameTotals = {};
    (Array.isArray(source) ? source : []).forEach((record) => {
      if (!nameTotals[record.name]) {
        nameTotals[record.name] = 0;
      }
      nameTotals[record.name] += record.price;
    });
    return Object.keys(nameTotals).sort(
      (a, b) => nameTotals[b] - nameTotals[a],
    );
  }, [source]);

  const calculateData = (source, type) => {
    if (!Array.isArray(source)) return [];

    const today = dayjs().startOf("day");
    const emptyDays = Array.from({ length: 15 }, (_, i) => ({
      date: dayjs().subtract(i, "day").format("MM/DD"),
    }));

    const groupedData = source.reduce((acc, { date, name, price }) => {
      const daysAgo = today.diff(dayjs(date).startOf("day"), "day");
      if (daysAgo >= 0 && daysAgo < 15) {
        if (!acc[daysAgo]) acc[daysAgo] = {};
        acc[daysAgo][name] =
          (acc[daysAgo][name] || 0) + (type === "amount" ? 1 : price);
      }
      return acc;
    }, {});

    return emptyDays.reverse().map((day, i) => ({
      ...day,
      ...groupedData[14 - i],
    }));
  };

  const data = useMemo(() => {
    if (!source) return [];
    return calculateData(source, type);
  }, [source, type]);

  return (
    <VStack align="center" minH={300}>
      <RadioGroup onChange={setType} value={type}>
        <Flex gap={4} fontSize="sm">
          <Radio value="amount">以筆數區分</Radio>
          <Radio value="sum">以金額區分</Radio>
        </Flex>
      </RadioGroup>

      <ResponsiveContainer height={300} width="100%">
        <BarChart
          width={1200}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            bottom: 5,
          }}
        >
          <XAxis dataKey="date" stroke="#e3e3e3" />
          <YAxis allowDecimals={false} stroke="#e3e3e3" />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          {names.map((name, index) => (
            <Bar
              key={name}
              dataKey={name}
              stackId="a"
              fill={colors[index % colors.length]}
              barSize={20}
              unit={type === "amount" ? "筆" : "元"}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </VStack>
  );
};

export default RevenueChart;
