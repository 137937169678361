import React, {useEffect, useState} from "react";
import {Avatar, Box, Heading, HStack, Table, Tbody, Td, Text, Th, Thead, Tr, VStack,} from "@chakra-ui/react";
import getPublicDownloadUrl from "libs/get-public-download-url";
import {useFirebase} from "context/firebase.context";
import {collection, doc, getDoc, onSnapshot, query, where,} from "firebase/firestore";
import dayjs from "dayjs";
import MainLayout from "../../layouts/main.layout";

const EarningRow = React.memo(
    ({id, totalPoints, pointsByDay, userData}) => {
        const name = userData?.displayName;
        const picture = userData?.picture;

        return (
            <Tr>
                <Td>{id}</Td>
                <Td>
                    <HStack spacing={3}>
                        <Avatar size="sm" src={getPublicDownloadUrl(picture)}/>
                        <Text>{name || "載入中..."}</Text>
                    </HStack>
                </Td>
                <Td isNumeric>{pointsByDay.today || 0}</Td>
                <Td isNumeric>{pointsByDay.yesterday || 0}</Td>
                <Td isNumeric>{pointsByDay.twoDaysAgo || 0}</Td>
                <Td isNumeric>{totalPoints}</Td>
            </Tr>
        );
    },
);

export default function EarningsPage() {
    const [creatorEarnings, setCreatorEarnings] = useState([]);
    const [userEarnings, setUserEarnings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [usersData, setUsersData] = useState({});
    const [creatorsData, setCreatorsData] = useState({});
    const {firestore} = useFirebase();

    // Effect for fetching users and creators data
    useEffect(() => {
        const fetchUserAndCreatorData = async () => {
            if (!creatorEarnings.length && !userEarnings.length) return;

            const creatorIds = creatorEarnings.map((e) => e.id);
            const userIds = userEarnings.map((e) => e.id);

            if (creatorIds.length) {
                const creatorPromises = creatorIds.map((id) =>
                    getDoc(doc(firestore, "creators", id)),
                );

                const creatorSnapshots = await Promise.all(creatorPromises);
                const creatorData = {};
                creatorSnapshots.forEach((docSnap) => {
                    if (docSnap.exists()) {
                        creatorData[docSnap.id] = {id: docSnap.id, ...docSnap.data()};
                    }
                });
                setCreatorsData(creatorData);
            }

            if (userIds.length) {
                const userPromises = userIds.map((id) =>
                    getDoc(doc(firestore, "users", id)),
                );

                const userSnapshots = await Promise.all(userPromises);
                const userData = {};
                userSnapshots.forEach((docSnap) => {
                    if (docSnap.exists()) {
                        userData[docSnap.id] = {id: docSnap.id, ...docSnap.data()};
                    }
                });
                setUsersData(userData);
            }
        };

        fetchUserAndCreatorData();
    }, [firestore, creatorEarnings, userEarnings]);

    useEffect(() => {
        const threeDaysAgo = dayjs().subtract(7, "days").valueOf();

        const q = query(
            collection(firestore, "transactions"),
            where("createdAt", ">=", threeDaysAgo),
        );

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const transactions = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            // Group by creatorId/userId and sum points
            const grouped = transactions.reduce((acc, transaction) => {
                const key =
                    transaction.scope === "CREATOR"
                        ? transaction.creatorId
                        : transaction.userId;

                if (!key) return acc;

                if (!acc[key]) {
                    acc[key] = {
                        id: key,
                        scope: transaction.scope,
                        totalPoints: 0,
                        pointsByDay: {
                            today: 0,
                            yesterday: 0,
                            twoDaysAgo: 0,
                        },
                    };
                }

                const points = transaction.points || 0;
                acc[key].totalPoints += points;

                // Determine which day this transaction belongs to
                const today = dayjs().startOf("day");
                const transactionDate = dayjs(transaction.createdAt);

                if (transactionDate.isSame(today, "day")) {
                    acc[key].pointsByDay.today += points;
                } else if (transactionDate.isSame(today.subtract(1, "day"), "day")) {
                    acc[key].pointsByDay.yesterday += points;
                } else if (transactionDate.isSame(today.subtract(2, "day"), "day")) {
                    acc[key].pointsByDay.twoDaysAgo += points;
                }

                return acc;
            }, {});

            const earnings = Object.values(grouped);

            // Split and sort by points
            const creators = earnings
                .filter((e) => e.scope === "CREATOR" && Math.abs(e.totalPoints) > 2000)
                .sort((a, b) => b.totalPoints - a.totalPoints);

            const users = earnings
                .filter((e) => e.scope === "USER" && Math.abs(e.totalPoints) > 2000)
                .sort((a, b) => Math.abs(b.totalPoints) - Math.abs(a.totalPoints));

            setCreatorEarnings(creators);
            setUserEarnings(users);
            setLoading(false);
        });

        return () => unsubscribe();
    }, [firestore]);

    if (loading) {
        return (
            <MainLayout>
                <Box p={4}>
                    <Text>載入中...</Text>
                </Box>
            </MainLayout>
        );
    }

    return (
        <MainLayout>
            <Box p={4}>
                <VStack spacing={8} align="stretch">
                    <Box>
                        <Heading size="lg" mb={6}>
                            創作者收益查詢
                        </Heading>
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>ID</Th>
                                    <Th>創作者名稱</Th>
                                    <Th isNumeric>今天</Th>
                                    <Th isNumeric>昨天</Th>
                                    <Th isNumeric>前天</Th>
                                    <Th isNumeric>總點數</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {creatorEarnings.map((earning) => (
                                    <EarningRow
                                        key={earning.id}
                                        {...earning}
                                        userData={creatorsData[earning.id]}
                                    />
                                ))}
                            </Tbody>
                        </Table>
                    </Box>

                    <Box>
                        <Heading size="lg" mb={6}>
                            用戶花費／儲值查詢 (正的數是儲值，負的是花費）
                        </Heading>
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>ID</Th>
                                    <Th>用戶名稱</Th>
                                    <Th isNumeric>今天</Th>
                                    <Th isNumeric>昨天</Th>
                                    <Th isNumeric>前天</Th>
                                    <Th isNumeric>總點數</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {userEarnings.map((earning) => (
                                    <EarningRow
                                        key={earning.id}
                                        {...earning}
                                        userData={usersData[earning.id]}
                                    />
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                </VStack>
            </Box>
        </MainLayout>
    );
}
